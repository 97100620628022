@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300..700&family=Syne:wght@400..600&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', 'Inter Fallback', -apple-system, sans-serif;
  line-height: 1.6;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

html,
body,
#__next {
  height: 100%;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

.menu-items-swiper .swiper-pagination {
  position: fixed;
  top: 105px;
  z-index: 210;
  width: 100vh;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
          appearance: textfield;
}

